// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-components-post-js": () => import("./../../../src/pages/components/Post.js" /* webpackChunkName: "component---src-pages-components-post-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posts-001-humble-beginnings-js": () => import("./../../../src/pages/posts/001-humble-beginnings.js" /* webpackChunkName: "component---src-pages-posts-001-humble-beginnings-js" */),
  "component---src-pages-posts-001-indie-game-dev-js": () => import("./../../../src/pages/posts/001-indie-game-dev.js" /* webpackChunkName: "component---src-pages-posts-001-indie-game-dev-js" */),
  "component---src-pages-posts-002-play-farmhand-go-js": () => import("./../../../src/pages/posts/002-play-farmhand-go.js" /* webpackChunkName: "component---src-pages-posts-002-play-farmhand-go-js" */),
  "component---src-pages-posts-002-weekend-game-journal-js": () => import("./../../../src/pages/posts/002-weekend-game-journal.js" /* webpackChunkName: "component---src-pages-posts-002-weekend-game-journal-js" */),
  "component---src-pages-posts-003-hot-spud-demo-released-js": () => import("./../../../src/pages/posts/003-hot-spud-demo-released.js" /* webpackChunkName: "component---src-pages-posts-003-hot-spud-demo-released-js" */),
  "component---src-pages-type-demo-js": () => import("./../../../src/pages/type-demo.js" /* webpackChunkName: "component---src-pages-type-demo-js" */)
}

